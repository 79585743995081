import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, combineLatest, first, map, take } from 'rxjs';
import { AuthObject } from '@neuralegion/auth-objects-lookup-api';

export function authObjectProjectAsyncValidator(
  projectId$: Observable<string | null>,
  authObjects$: Observable<AuthObject[] | null>
): AsyncValidatorFn {
  return (control: AbstractControl) =>
    combineLatest([projectId$, authObjects$]).pipe(
      map(([projectId, authObjects]) => {
        if (!control?.value || !projectId || !authObjects) {
          return null;
        }

        const selectedAuthObjectProjectId = authObjects.find(
          (authObject: AuthObject) => authObject.id === control.value
        )?.projectId;

        return !selectedAuthObjectProjectId || selectedAuthObjectProjectId === projectId
          ? null
          : { authObjectProjectsEqualsSelectedProject: true };
      }),
      take(1)
    );
}

export function authObjectRepeaterAsyncValidator(
  selectAuthObjectFn: (id: string) => Observable<AuthObject>,
  repeaterIdControl: { enabled: boolean; value: string | string[] | null }
): AsyncValidatorFn {
  return (control: AbstractControl<string | null>) =>
    selectAuthObjectFn(control.value).pipe(
      first(Boolean),
      map((selectedAuthObject: AuthObject) => {
        if (!control.value || !selectedAuthObject) {
          return null;
        }

        const repeaterSelected =
          repeaterIdControl.enabled &&
          (Array.isArray(repeaterIdControl.value)
            ? repeaterIdControl.value?.length > 0
            : !!repeaterIdControl.value);

        return selectedAuthObject.repeaterRequired && !repeaterSelected
          ? { authObjectRequiresRepeater: true }
          : null;
      })
    );
}
